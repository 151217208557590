import React from 'react';
import Layout from '../components/layout';

const Contact = ({location}) => {
    const start = location.state ? `${location.state.name}, thank` :  `Thank`;
    return (
        <Layout>
            <div className="container">
                <div className="register_form">
                    <h3>{start} you for getting in touch!</h3>
                    <p><i>We appreciate you contacting us. One of our colleagues will get back in touch with you soon!</i></p>
                    <p>Have a great day!</p>
                </div>
            </div>
        </Layout>
    )
}

export default Contact;